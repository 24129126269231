import React from 'react';
import './BottomMenu.css';
import { Link } from 'react-router-dom';

const BottomMenu = () => {
  return (
    <div className="bottom-menu">
      <ul>
        <li><Link to="/mark-points">Show Points</Link></li>
        <li><Link to="/search-points">Search Points</Link></li>
        <li><Link to="/notify">Notify</Link></li>
      </ul>
    </div>
  );
};

export default BottomMenu;
