import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate('/other-page');
  };

  return (
    <div className="center">
      <table>
     <tr><td> <h1>Your Personal Guide to Wellness Through Acupressure</h1></td></tr>
<tr>
<td align="justify">
<h3>
About ACCURE:<><>
ACCURE is a revolutionary mobile app designed to bring the ancient practice of acupressure to your fingertips. Whether you're seeking relief from pain, stress, or simply looking to enhance your overall well-being, ACCURE offers personalized routines to meet your needs.
</>
</>
</h3>
</td>
</tr>
</table>

    </div>
  );
};

export default Home;
