import React, { useState, useEffect, useRef } from 'react';
import TouchEvents from 'react-touch-events';

const ImageMarker = ({ imageUrl }) => {
  const [points, setPoints] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [inputNames, setInputNames] = useState('');
  const imageRef = useRef(null);

  
  useEffect(() => {
    // Fetch coordinates from JSON file
    fetch('/AccuPointsLocation3.json')
      .then(response => response.json())
      .then(data => {
        if (data.coordinates) {
          setCoordinates(data.coordinates);
        }
      })
      .catch(error => console.error('Error fetching coordinates:', error));
  }, []);

  const getRelativeCoordinates = (absoluteX, absoluteY, rect) => {
    const x = ((absoluteX - rect.left) / rect.width) * 100;
    const y = ((absoluteY - rect.top) / rect.height) * 100;
    return { x, y };
  };



  const handleEvent = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = ((e.clientX || e.pageX) - rect.left) / rect.width * 100;
      const y = ((e.clientY || e.pageY) - rect.top) / rect.height * 100;
      setPoints([...points, { x, y }]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => handleEvent(e);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [points]);






  const handleClick = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const { x, y } = getRelativeCoordinates(e.clientX, e.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleTouch = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const touch = e.changedTouches[0];
    const { x, y } = getRelativeCoordinates(touch.clientX, touch.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleInput = () => {
    const names = inputNames.split(',').map(name => name.trim());
    
    
    const selectedPoints = coordinates.filter(coord => names.includes(coord.name));
    setPoints(selectedPoints.map(coord => ({ x: coord.x, y: coord.y ,color: coord.color, img: coord.img })));
  };


  
  return (
    <div>

<div>
<label htmlFor="points">Enter Points</label>
        <input
          type="text"
          value={inputNames.toUpperCase()}
          onChange={(e) => setInputNames(e.target.value.toUpperCase())}
          placeholder="e.g., G1, G5 (comma separated)"
        />


          
        <button onClick={handleInput}>Show Points</button>
      </div>

      <TouchEvents>
      
        <div style={{ position: 'relative', display: 'inline-block' }}> 
          <img
            ref={imageRef}
            src={imageUrl}
            alt="Markable"
            onClick={handleClick}
            style={{ maxWidth: '100%', height: 'auto' }}
            
          />
          {points.map((point, index) => (
            
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${point.y}%`,
                left: `${point.x}%`,
                width: '10px',
                height: '10px',
                backgroundColor: point.color==null?"black":point.color,
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
            
          ))}

        </div>
     
      </TouchEvents>
   
      <p>
        { /*{points.map((point, index) => `{ "name": "pointName", "x":${point.x.toFixed(2)}, "y": ${point.y.toFixed(2)} , "color":"yellow"}`).join(', ')}
          */}
       

        </p>
    </div>
  );
};

export default ImageMarker;
