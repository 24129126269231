import React, { useState, useEffect } from 'react';
import ImageMarker from '../pages/ImageMarker.js';


const SearchPoints = () => {

  

  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
    fetch('/coordinates3.json')
      .then((response) => response.json())
      .then((data) => {
        if (data && data.coordinates) {
          setCoordinates(data.coordinates);
        }
      })
      .catch((error) => console.error('Error fetching coordinates:', error));
  }, []);



  return (
    <div>
    <h1>Accupressure Point Locator</h1>
 
    <ImageMarker imageUrl="LeftHandFront-WithOutMeridian.png" initialCoordinates={coordinates} />
    </div>


  );
};

export default SearchPoints;
