import React from 'react';
import { useParams } from 'react-router-dom';
import './ImagePage.css';

const ImagePage = () => {
  const { imageType } = useParams();

  const imageSrc = imageType === 'front' ? '/GB-palmAsset.png' : '/GB-backAsset.png';
  const description = imageType === 'front' ? 'This is the front hand image.' : 'This is the back hand image.';

  return (
    <div className="image-page">
      <h1>{imageType === 'front' ? 'Front Hand' : 'Back Hand'}</h1>
      <img src={imageSrc} alt={`${imageType} hand`} />
      <p>{description}</p>
    </div>
  );
};

export default ImagePage;
