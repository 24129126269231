const pointsMapping = {
    "G1": { X: 114, Y: 654, side: "front" },
    "G2": { X: 161, Y: 654, side: "front" },
    "G3": { X: 132, Y: 642, side: "front" },
    "G4": { X: 87, Y: 624, side: "front" },
    "G5": { X: 105, Y: 627, side: "front" },
    "G6": { X: 118, Y: 628, side: "front" },
    "G7": { X: 131, Y: 632, side: "front" },
    "G13": { X: 60, Y: 628, side: "front" },
    "G14": { X: 81, Y: 649, side: "front" },
    "G15": { X: 63, Y: 639, side: "front" },
    "G16": { X: 48, Y: 631, side: "front" },
    "G22": { X: 541, Y: 506, side: "front" },
    "G23": { X: 539, Y: 521, side: "front" },
    "G24": { X: 536, Y: 851, side: "front" },
    "G25": { X: 386, Y: 715, side: "front" },
    "G26": { X: 419, Y: 666, side: "front" },
    "G27": { X: 445, Y: 628, side: "front" },
    "G28": { X: 459, Y: 606, side: "front" },
    "G29": { X: 473, Y: 586, side: "front" },
    // Back side
    "G17": { X: 920, Y: 645, side: "back" },
    "G18": { X: 889, Y: 678, side: "back" },
    "G19": { X: 857, Y: 697, side: "back" },
    "G20": { X: 824, Y: 716, side: "back" },
    "G21": { X: 606, Y: 650, side: "back" },
    "G8": { X: 864, Y: 638, side: "back" },
    "G9": { X: 856, Y: 650, side: "back" },
    "G10": { X: 848, Y: 662, side: "back" },
    "G11": { X: 840, Y: 677, side: "back" },
    "G12": { X: 832, Y: 690, side: "back" },
    "G30": { X: 400, Y: 512, side: "back" },
    "G31": { X: 400, Y: 395, side: "back" },
    "G32": { X: 400, Y: 376, side: "back" },
    "G33": { X: 400, Y: 320, side: "back" },
    "G34": { X: 400, Y: 269, side: "back" },
    "G35": { X: 400, Y: 242, side: "back" },
    "G36": { X: 383, Y: 241, side: "back" },
    "G37": { X: 400, Y: 230, side: "back" },
    "G38": { X: 400, Y: 220, side: "back" },
    "G39": { X: 400, Y: 209, side: "back" },
    "G40": { X: 400, Y: 181, side: "back" },
    "G41": { X: 400, Y: 149, side: "back" },
    "G42": { X: 400, Y: 140, side: "back" },
    "G43": { X: 400, Y: 129, side: "back" },
    "G44": { X: 400, Y: 109, side: "back" },




    "cv1": {X:67.46, Y: 36.99, side: "front" }, 
   "cv1": {X:67.46, Y: 36.99, side: "front" }, 
    "cv2": { X:67.26, Y: 41.00, side: "front" }, 
    "cv3": { X:67.26, Y: 44.86, side: "front" }, 
    "cv4": { X:66.85, Y: 48.71, side: "front" }, 
    "cv5": { X:67.06, Y: 52.09, side: "front" }, 
    "cv6": { X:66.85, Y: 53.53, side: "front" }, 
    "cv7": { X:66.65, Y: 56.43, side: "front" }, 
    "cv8": { X:66.65, Y: 59.80, side: "front" }, 
    "cv9": { X:66.44, Y: 63.65, side: "front" }, 
    "cv10": { X:66.44, Y: 67.51, side: "front" }, 
    "cv11": { X:66.24, Y: 71.53, side: "front" }, 
    "cv12": { X:66.03, Y: 75.86, side: "front" }, 
    "cv13": { X:66.03, Y: 79.88, side: "front" }, 
    "cv14": { X:65.83, Y: 83.73, side: "front" }, 
    "cv15": { X:61.11, Y: 80.84, side: "front" }, 
    "cv16": { X:57.43, Y: 78.11, side: "front" }, 
    "cv17": { X:52.92, Y: 74.90, side: "front" }, 
    "cv18": { X:48.62, Y: 72.01, side: "front" }, 
    "cv19": { X:44.31, Y: 69.12, side: "front" }, 
    "cv20": { X:39.81, Y: 65.74, side: "front" }, 
    "cv21": { X:36.53, Y: 63.65, side: "front" }, 
    "cv22": { X:31.82, Y: 60.44, side: "front" }, 
    "cv23": { X:18.50, Y: 54.98, side: "front" }, 
    "cv24": { X:14.61, Y: 53.37, side: "front" }

  };
  
  export default pointsMapping;
  