import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import logo_dark from "../Assets/logo.png";
import Home from '../pages/Home';
import OtherPage from '../pages/OtherPage';
import MarkPoints from '../pages/MarkPoints';
import SearchPoints from '../pages/SearchPoints';
import ImagePage from '../pages/ImagePage';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	return (
		<header>
            <img src={logo_dark} alt="accure" />
			<nav ref={navRef}>
				<Link to="/">Home</Link>
				<Link to="/mark-points">Show Points</Link>
				<Link to="/search-points">SearchPoints</Link>
				<Link to="/#">About me</Link>



				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;